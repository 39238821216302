<template>
  <div>
    <el-table
      :data="orderDetails.refundItems"
      size="small"
      row-key="id"
      :border="true"
      :default-expand-all="false"
    >
      <el-table-column :align="'center'" label="商品名称" prop="goodsName">
      </el-table-column>
      <el-table-column :align="'center'" label="规格图片">
        <template slot-scope="scope">
          <div class="demo-image__preview">
            <el-image
              style="width: 36px; height: 36px"
              :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : ''"
              :preview-src-list="[
                scope.row.goodsSkuImg ? scope.row.goodsSkuImg : '',
              ]"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :align="'center'"
        label="商品规格"
        prop="goodsSkuName"
      ></el-table-column>
      <el-table-column :align="'center'" label="商品价格" prop="scorePrice">
      </el-table-column>
      <el-table-column :align="'center'" label="数量" prop="quantity">
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-form :rules="rules" ref="ruleForm" :model="form" label-width="90px">
        <el-form-item label="售后原因:" prop="reason">
          <el-select
            style="width: 500px"
            v-model="form.reason"
            placeholder="请选择"
          >
            <el-option
              v-for="item in resonList"
              :key="item.id"
              :label="item.reason"
              :value="item.reason"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细描述:" prop="remark">
          <el-input
            type="textarea"
            :rows="4"
            style="width: 500px"
            placeholder="请输入补充原因(非必填)"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" style="margin-left: 20px" @click="onSumbit"
          >确 定</el-button
        >
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  refundCalculate,
  refundSubmit,
  refundDetails,
} from "@/api/supplierGoods/after";
export default {
  name: "twoSales",
  props: {
    goodsItem: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      orderDetails: {},
      form: {},
      resonList: [],
      rules: {
        reason: [
          { required: true, message: "请选择售后原因", trigger: "change" },
        ],
      },
    };
  },
  created() {
    if (this.goodsItem.id) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      refundDetails(this.goodsItem.id).then(async (res) => {
        this.orderDetails = res.data;
        this.getCalculate();
      });
    },
    getCalculate() {
      refundCalculate({
        orderId: this.orderDetails.orderId,
        refundItems: this.orderDetails.refundItems,
        type: 2,
      }).then(async (res) => {
        this.resonList = res.data.refundReasons;
      });
    },
    onSumbit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          refundSubmit({
            ...this.form,
            orderId: this.orderDetails.orderId,
           refundItems: this.orderDetails.refundItems,
           type:2,
          }).then((res) => {
            this.$emit("closeTwoSale");
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    close() {
      this.$emit("closeTwoSale");
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-footer {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
