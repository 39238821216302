<template>
  <div class="main-content">
    <div class="selCard mb14">
      <el-form
        size="small"
        inline
        :model="tableFrom"
        ref="searchForm"
        label-width="85px"
      >
        <el-form-item label="供应商：" prop="vendorId">
          <el-select
            v-model="tableFrom.vendorId"
            placeholder="请选择"
            class="input-with-select selWidth"
            filterable
            clearable
          >
            <el-option
              v-for="(item, index) in supplierList"
              :key="index"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="售后单号：" prop="code">
          <el-input
            v-model="tableFrom.code"
            placeholder="请输入售后单号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>
        <el-form-item label="订单编号：" prop="orderCode">
          <el-input
            v-model="tableFrom.orderCode"
            placeholder="请输入订单编号"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>

        <el-form-item label="用户名称：" prop="nickname">
          <el-input
            v-model="tableFrom.nickname"
            placeholder="请输入用户名称"
            class="input-with-select selWidth"
            clearable
          />
        </el-form-item>

        <el-form-item label="售后类型：" prop="type">
          <el-select
            v-model="tableFrom.type"
            class="input-with-select selWidth"
            size="medium"
            clearable
          >
            <el-option
              v-for="item in statusTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售后状态：" prop="status">
          <el-select
            v-model="tableFrom.status"
            class="input-with-select selWidth"
            size="medium"
            clearable
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
          <el-button size="small" @click="exportFun()">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="box-card">
      <el-button
        size="mini"
        style="margin-bottom: 5px"
        type="primary"
        @click="manualReturn()"
        >手动退款</el-button
      >
      <el-button
        size="mini"
        style="margin-bottom: 5px"
        type="primary"
        @click="uploadReturnType()"
        >修改售后类型</el-button
      >
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        row-key="id"
        :border="false"
        :default-expand-all="false"
        ref="refsTable"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        >
        </el-table-column>
        <el-table-column :align="'center'" label="售后单号" prop="code">
        </el-table-column>
        <el-table-column :align="'center'" label="订单编号" prop="orderCode">
        </el-table-column>
        <!-- <el-table-column :align="'center'" label="订单ID" prop="orderId">
        </el-table-column> -->
        <el-table-column :align="'center'" label="用户名称" prop="nickname">
        </el-table-column>
        <!-- <el-table-column :align="'center'" label="售后原因" prop="reason">
        </el-table-column> -->
        <el-table-column :align="'center'" label="申请时间" prop="refundTime">
        </el-table-column>
        <el-table-column :align="'center'" label="售后状态">
          <template slot-scope="scope">
            {{ stateData[String(scope.row.status)] }}
          </template>
        </el-table-column>
        <el-table-column
          label="售后类型"
          :align="'center'"
          prop="type"
          min-width="50"
        >
          <template slot-scope="scope">
            {{
              scope.row.type === 1
                ? "仅退款"
                : scope.row.type === 2
                ? "退货退款"
                : "换货"
            }}
          </template>
        </el-table-column>
        <el-table-column
          label="售后描述"
          :align="'center'"
          prop="remark"
          min-width="150"
        >
        </el-table-column>
        <el-table-column label="操作" :align="'center'" width="270px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              v-if="scope.row.status === 0"
              icon="el-icon-info"
              @click="onShipments(scope.row, 1)"
              >审核</el-button
            > 
            <el-button
              type="text"
              size="mini"
              icon="el-icon-info"
              @click="onShipments(scope.row, 2)"
              >查看详情</el-button
            >
            <el-button
              type="text"
              size="mini"
              v-if="scope.row.type === 3 && scope.row.status === 50"
              icon="el-icon-info"
              @click="twoSalesFun(scope.row)"
              >售后</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <el-dialog
      :visible.sync="afterShow"
      :before-close="resetForm"
      size="55%"
      :title="title"
      :with-header="false"
    >
      <div style="margin-bottom: 10px">
        <el-table
          :data="orderDetails.refundItems"
          size="small"
          row-key="id"
          :border="true"
          :default-expand-all="false"
        >
          <el-table-column type="index" width="50" label="序号" />
          <el-table-column :align="'center'" label="商品名称" prop="goodsName">
          </el-table-column>
          <el-table-column :align="'center'" label="规格图片">
            <template slot-scope="scope">
              <div class="demo-image__preview">
                <el-image
                  style="width: 36px; height: 36px"
                  :src="scope.row.goodsSkuImg ? scope.row.goodsSkuImg : ''"
                  :preview-src-list="[
                    scope.row.goodsSkuImg ? scope.row.goodsSkuImg : '',
                  ]"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :align="'center'"
            label="商品规格"
            prop="goodsSkuName"
          ></el-table-column>
          <el-table-column :align="'center'" label="数量" prop="quantity">
          </el-table-column>

          <template
            v-if="
              orderDetails.refundItems.length > 0 &&
              orderDetails.refundItems[0].newGoodsSkuName
            "
          >
            <el-table-column
              :align="'center'"
              label="商品规格（新）"
              prop="newGoodsSkuName"
            >
            </el-table-column>
            <el-table-column :align="'center'" label="规格图片（新）">
              <template slot-scope="scope">
                <div class="demo-image__preview">
                  <el-image
                    style="width: 36px; height: 36px"
                    :src="
                      scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : ''
                    "
                    :preview-src-list="[
                      scope.row.newGoodsSkuImg ? scope.row.newGoodsSkuImg : '',
                    ]"
                  />
                </div>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <el-form
        label-width="120px"
        :model="form"
        :rules="ruleList()"
        ref="ruleForm"
      >
        <el-form-item label="审核结果" prop="auditStatus">
          <el-radio-group v-model="form.auditStatus">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">不通过</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="收货地址"
          prop="address"
          v-if="form.auditStatus == 1 && returnType != 1"
        >
          <el-button
            type="primary"
            v-if="!form.address"
            @click="
              () => {
                this.expressDialogVisible = true;
              }
            "
            size="mini"
            >请选择收货地址</el-button
          >
          <div v-else>
            <el-button
              type="primary"
              size="mini"
              @click="
                () => {
                  this.expressDialogVisible = true;
                }
              "
              >修改</el-button
            >
            <div v-if="form.addressInfo.brandList.length > 0">
              <span
                v-for="(item, index) in form.addressInfo.brandList"
                :key="index"
                ><span v-if="index != 0">,</span>{{ item.name }}</span
              >
            </div>
            <div>
              {{ form.addressInfo.province }}{{ form.addressInfo.city
              }}{{ form.addressInfo.district }}{{ form.addressInfo.address }}
            </div>
          </div>
          <!-- <div v-else>{{form.addressInfo.name}} 
            
            </div> -->
          <!-- <el-select v-model="form.address" size="mini" placeholder="请选择">
            <el-option
              v-for="item in addressList"
              :key="item.id"
              :label="item.address"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <template v-if="orderDetails.type == 1 || orderDetails.type == 2">
          <el-form-item
            label="实退金额"
            v-if="form.auditStatus === 1 && orderDetails.orderRefundAmount"
          >
            <el-input-number
              v-model="form.realAmount"
              :precision="2"
              :step="0.1"
              :max="orderDetails.refundItems[0].goodsSkuPrice"
            ></el-input-number>
          </el-form-item>

          <el-form-item
            label="实退余额"
            v-if="form.auditStatus === 1 && orderDetails.orderRefundBalance"
          >
            <el-input-number
              v-model="form.realBalance"
              :precision="2"
              :step="0.1"
              :max="orderDetails.refundItems[0].goodsSkuPrice"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="实退积分"
            v-if="form.auditStatus === 1 && orderDetails.orderRefundScore"
          >
            <el-input-number
              v-model="form.realScore"
              :precision="2"
              :step="0.1"
              :max="orderDetails.refundItems[0].goodsSkuPrice"
            ></el-input-number>
          </el-form-item>
        </template>
        <el-form-item
          label="商家拒绝描述"
          :prop="'remark'"
          v-if="form.auditStatus === 0"
        >
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="resetForm('ruleForm')" size="mini">取消</el-button>
          <el-button
            type="primary"
            :loading="loadings"
            size="mini"
            @click="submitForm('ruleForm')"
            >确认</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <ReturnOrderDetails
      v-if="afterDetailsShow"
      @closeDrawer="closeDrawer"
      :drawer="afterDetailsShow"
      :orderId="orderId"
      ref="returnOrderDetails"
    />
    <el-dialog
      title="快递信息"
      :visible.sync="expressDialogVisible"
      width="800px"
    >
      <el-table :data="addressList" size="small" :border="false">
        <el-table-column type="index" width="100" label="序号" />
        <el-table-column prop="name" label="收货人姓名" min-width="120" />
        <el-table-column prop="mobile" label="收货人手机号" min-width="120" />
        <el-table-column prop="ip" label="省市区" min-width="120">
          <template slot-scope="scope">
            <div>
              {{ scope.row.province }}{{ scope.row.city
              }}{{ scope.row.district }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="收货人地址" min-width="120" />
        <el-table-column prop="brand" label="品牌" min-width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.brandList.length > 0">
              <span v-for="(item, index) in scope.row.brandList" :key="index"
                ><span v-if="index != 0">,</span>{{ item.name }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="120"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click="changeAddress(scope.row)"
              >选择地址</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <ReturnByUser
      v-if="showReturn"
      :orderItem="selectionOne[0]"
      :show="showReturn"
    />

    <!-- 售后弹框 二次售后只能退款 -->
    <el-dialog
      title="退货退款"
      :visible.sync="twoSalesDialog"
      width="800px"
    >
       <TwoSales v-if="twoSalesDialog" @closeTwoSale="twoSalesDialog = false,getList()" :goodsItem="checkOne" />

    </el-dialog>
  </div>
</template>

<script>
import { afterState } from "./orders";
import ReturnByUser from "./components/returnByUser";
import {
  list,
  refundDetails,
  afterPageList,
  refundConfirm,
  refundShipping,
  getSupplierAddress,
  refundUpdateType,
} from "@/api/supplierGoods/after";
import ReturnOrderDetails from "./components/returnOrderDetails";
import { getSupplierListAll } from "@/api/supplier/supplier";
import { refundAllPageList, refundAudit } from "@/api/goods/goods";
import { expressLogistics } from "@/api/supplierGoods/order";
import { expressList } from "@/api/supplierGoods/order";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import timeOptions from "@/assets/utils/timeOptions";
import roleVue from "../../components/system/user/role.vue";
import { getIndex } from "@/utils/other";
import { exportExcel } from "@/assets/utils/exportExcel";
import TwoSales from './twoSales.vue';
export default {
  name: "ProductClassify",
  components: {
    FormImgUpload,
    ReturnOrderDetails,
    ReturnByUser,
    TwoSales
  },
  data() {
    return {
      twoSalesDialog:false,//二次售后弹框
      checkOne:{},//选中的数据
      showReturn: false,
      selectionOne: [],
      isShow: false,
      addressList: [],
      orderId: "",
      expressDialogVisible: false,
      userExpressList: [],
      supplierList: [], //供应商列表
      shangExpressList: [],
      afterDetailsShow: false,
      title: "",
      afterList: [], //售后原因列表
      statusTypeList: [
        {
          label: "仅退款",
          value: 1,
        },
        {
          label: "退货退款",
          value: 2,
        },
        {
          label: "换货",
          value: 3,
        },
      ],
      expressNum: 0,
      statusList: [
        {
          label: "待审核",
          value: 0,
        },
        {
          label: "待用户退货",
          value: 10,
        },
        {
          label: "商家待收货",
          value: 20,
        },
        {
          label: "商家待发货",
          value: 21,
        },
        {
          label: "退款中",
          value: 30,
        },
        {
          label: "用户待收货",
          value: 40,
        },
        {
          label: "已完成",
          value: 50,
        },
        {
          label: "已关闭",
          value: 90,
        },
        {
          label: "商家拒绝",
          value: 91,
        },
      ],
      stateData: afterState, //状态对象数据
      pickerOptions: timeOptions,
      tabPosition: 0,
      orderDetails: {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      },
      activeName: "first", //tab切换默认
      afterShow: false,
      type: 1,
      returnType: 1,
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "",
        type: "",
        code: "",
        orderCode: "",
        nickname: "",
      },
      title: "新增分类",
      form: { remark: "", auditStatus: 1, address: "" },
      value: [],
      loadings: false,
      expressForm: {
        expressCompanyCode: "",
        expressNo: "",
      },
      expressLists: [],
      rules: {
        expressCompanyCode: [
          { required: true, message: "请选择物流公司：", trigger: "blur" },
        ],
        expressNo: [
          { required: true, message: "请填写物流单号", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getExpressLists();
    this.getList();
    this.getSupplierList();
  },
  methods: {
    //二次售后
    twoSalesFun(row){
      this.checkOne = row
      console.log('1231')
      this.twoSalesDialog = true
    },
    //关闭退款弹框
    closeReturn() {
      this.showReturn = false;
      this.getList();
    },
    exportFun() {
      exportExcel("/order/web/refund/all/pageListExport", {
        ...this.tableFrom,
        time: "",
        pageNum: 1,
        responseType: "blob",
        exportExcelName: "售后订单列表",
      });
    },
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    //获取供应商列表
    getSupplierList() {
      getSupplierListAll({}).then((res) => {
        this.supplierList = res.data;
        // this.tableFrom.supplierId = res.data[0].id;
        this.getList();
      });
    },
    // 获取物流列表
    async getExpressLists() {
      const { data } = await expressList();
      this.expressLists = data;
    },
    // 换货确认发货
    submitExpress() {
      this.$refs["expressForms"].validate(async (valid) => {
        if (valid) {
          this.loadings = true;
          let items = this.orderDetails.refundItems.map((val) => {
            return {
              goodsId: val.goodsId,
              goodsSkuId: val.goodsSkuId,
              quantity: val.surplusNum,
            };
          });
          let res = await refundShipping({
            items,
            ...this.expressForm,
            refundId: this.orderDetails.id,
          });
          if (res.code === 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getList();
            this.isShow = false;
            this.$refs["expressForms"].resetFields();
            this.expressForm.expressCompanyCode = "";
            this.expressForm.expressNo = "";
          }

          this.loadings = false;
        } else {
          return false;
        }
      });
    },
    // 确认收货
    submitOrder() {
      this.$confirm("确认收到当前用户退货吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const res = await refundConfirm({ refundId: this.orderDetails.id });
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.afterDetailsShow = false;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    handleSelectionChange(selection) {
      this.selectionOne = selection;
      if (selection.length > 1) {
        //移除上一次选中行数据
        selection.shift();
        this.$refs.refsTable.clearSelection();
        //将当前选中行改为选中状态
        this.$refs.refsTable.toggleRowSelection(selection[0], true);
      }
    },

    ruleList() {
      return {
        auditStatus: [
          { required: true, message: "请选择售后状态", trigger: "blur" },
        ],
        address: [
          {
            required: this.form.auditStatus != 1 ? false : true,
            message: "请选择收货地址",
            trigger: "cahnge",
          },
        ],
        remark: [
          {
            required: this.form.auditStatus === 0 ? true : false,
            message: "请输入商家拒绝描述",
            trigger: "blur",
          },
        ],
      };
    },
    changeAddress(row) {
      this.form.address = row.id;
      this.form.addressInfo = row;
      this.expressDialogVisible = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.orderDetails.type == 1 || this.orderDetails.type == 2) {
            if (
              !this.form.realAmount &&
              !this.form.realBalance &&
              !this.form.realScore
            ) {
              this.$message.error("请填写退款金额");
              return;
            }
            let i =
              Number(this.form.realAmount || 0) +
              Number(this.form.realBalance || 0) +
              Number(this.form.realScore || 0);
            if (
              Number(i) > Number(this.orderDetails.refundItems[0].goodsSkuPrice)
            ) {
              this.$message.error(
                "退款金额不能大于订单金额" +
                  this.orderDetails.refundItems[0].goodsSkuPrice
              );
              return;
            }
          }

          this.loadings = true;
          let adderssChangeList = this.addressList.filter((item) => {
            return item.id == this.form.address;
          });

          let res = await refundAudit({
            ...this.form,
            realAmount: this.form.realAmount || 0,
            realBalance: this.form.realBalance || 0,
            realScore: this.form.realScore || 0,
            refundId: this.orderDetails.id,
            address: adderssChangeList[0],
          });
          if (res.code === 200) {
            this.loadings = false;
            this.$message.success(res.msg);
            this.resetForm();
            this.getList();
          } else {
            this.loadings = false;
            this.resetForm();
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
      this.afterShow = false;
      this.orderDetails = {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      };
    },
    closeDetails() {
      this.afterDetailsShow = false;
      this.orderDetails = {
        refundItems: [],
        shipping: { shippings: [] },
        receiver: { shippings: [] },
      };
    },
    // 售后原因列表
    // async getafterPageList() {
    //   const res = await afterPageList({type:2,pageSize:1000})
    //   if(res.code == 200) {
    //     this.afterList = res.data
    //   }
    // },
    getList() {
      this.listLoading = true;
      refundAllPageList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    //修改售后类型
    uploadReturnType() {
      if (!this.selectionOne.length) {
        this.$message.error("请选择数据");
        return;
      }
      this.$confirm("是否修改售后类型?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        refundUpdateType({ refundId: this.selectionOne[0].id })
          .then(({ message }) => {
            this.getList();
          })
          .catch(({ message }) => {
            // this.$message.error(message);
          });
      });
    },
    manualReturn() {
      if (!this.selectionOne.length) {
        this.$message.error("请选择数据");
        return;
      }
      if (this.selectionOne[0].status >= 50) {
        this.$message.error("该状态不支持退款");
        return;
      }
      this.$confirm("是否确认已走完退款流程?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.showReturn = true;
      });
    },
    // 编辑
    onShipments(row, type) {
      this.returnType = row.type;
      this.type = type;
      this.title =
        type === 1
          ? row.type === 1
            ? "仅退款审核"
            : row.type === 2
            ? "退货退款审核"
            : "换货审核"
          : "售后详情";
      this.getOrderDetails(row, type);
      // if (type === 1) {
      //   this.getAddressList(row);
      // } else if (type === 2) {
      //   this.orderId =row.id
      //   this.afterDetailsShow = true;
      // }
    },
    closeDrawer() {
      this.afterDetailsShow = false;
    },
    getAddressList(row) {
      getSupplierAddress(row.vendorId).then((res) => {
        this.addressList = res.data;
        let arr = this.addressList.filter((item) => {
          return item.isDefault == 1;
        });

        if (arr.length > 0) {
          this.form.address = arr[0].id;
        }
        this.afterShow = true;
      });
    },
    // 售后详情
    getOrderDetails(row, type) {
      refundDetails(row.id).then(async (res) => {
        if (res.data.refundItems.length) {
          res.data.refundItems.forEach((val) => {
            val.surplusNum = val.quantity - val.receiverQuantity;
          });
        }
        if (res.data.receiver && res.data.receiver.shippings.length) {
          res.data.receiver.shippings.map(async (item, index) => {
            res.data.receiver.shippings[index].tracesCopy =
              await this.getExpressLogistics(2, item.id, res.data.type);
          });
        }
        if (res.data.shipping && res.data.shipping.shippings.length) {
          res.data.shipping.shippings[0].traces =
            await this.getExpressLogistics(
              1,
              res.data.shipping.shippings[0].id,
              res.data.type
            );
        }
        this.orderDetails = res.data;
        if (type === 1) {
          this.getAddressList(row);
        } else if (type === 2) {
          this.orderId = row.id;
          this.afterDetailsShow = true;
        }

        // setTimeout(() => {
        //   this.orderDetails = res.data;
        // }, 1000);
      });
    },
    async getExpressLogistics(type, id, locType) {
      const res = await expressLogistics({
        type: locType,
        id,
      });
      if (type === 1) {
        return res.data.traces != "暂无轨迹信息"
          ? JSON.parse(res.data.traces)
          : [];
      }
      if (type === 2) {
        return res.data.traces != "暂无轨迹信息"
          ? JSON.parse(res.data.traces)
          : [];
      }
    },
    getCountAll() {
      if (this.orderDetails.orderItems) {
        let num = 0;
        this.orderDetails.orderItems.map((val) => {
          num += val.quantity;
        });
        return num;
      }
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
  display: none;
}
.headers {
  display: flex;
  align-items: center;
  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.85);
  }

  .orderno {
    font-weight: 500;
    font-size: 14px;
    color: #606266;
  }
}

/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 30px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
</style>
